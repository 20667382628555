import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { GET_PR_COUNT, GET_PR_FILTER_DROPDOWN, GET_PR_LIST_OPEN, GET_VENDOR_DASHBOARD_SINGLE_PR, } from "../../ApiEndPoints/ApiEndPoints";
import axios from "axios";
import TYPES from "../constants";



export const fetchPrCount=()=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.GET_PR_COUNT_LOADING
    });
    try {
      const response = await axios.get(`${BaseUrl}${GET_PR_COUNT}`);
      
      if (response.status === 200){
        dispatch({
          type: TYPES.GET_PR_COUNT_SUCCESS,
          payload: response.data.data,
        })
      }
      else{
        dispatch({
          type: TYPES.GET_PR_COUNT_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.GET_PR_COUNT_FAILURE,
        error:error.message,
      })
    }
  }
};

export const fetchPrListOpen=(page,pageSize,orderBy,orderDir,filterStatus,purchaseRequestGroups,searchBy)=>{
    return async (dispatch)=>{
      dispatch({
        type: TYPES.GET_ALL_PR_LIST_LOADING
      });
      try {
        const params = {
          page,
          pageSize,
          orderBy,
          orderDir,
          filterStatus,
          searchBy,
        };
        if (purchaseRequestGroups && purchaseRequestGroups?.length > 0) {
          params.purchaseRequestGroups = JSON.stringify(purchaseRequestGroups);
        }

        const response = await axios.get(`${BaseUrl}${GET_PR_LIST_OPEN}`, {
            params,
          });
        
        if (response.status === 200){
          dispatch({
            type: TYPES.GET_ALL_PR_LIST_SUCCESS,
            payload: response.data.data,
          })
        }
        else{
          dispatch({
            type: TYPES.GET_ALL_PR_LIST_FAILURE,
            error: "API request failed",
          })
        }
      } catch (error) {
        dispatch({
          type: TYPES.GET_ALL_PR_LIST_FAILURE,
          error:error.message,
        })
      }
    }
  };


  export const fetchPrFilterDropDown=()=>{
    return async (dispatch)=>{
      dispatch({
        type: TYPES.GET_PR_FILTER_LOADING
      });
      try {
        const response = await axios.get(`${BaseUrl}${GET_PR_FILTER_DROPDOWN}`);
        
        if (response.status === 200){
          dispatch({
            type: TYPES.GET_PR_FILTER_SUCCESS,
            payload: response.data.data,
          })
        }
        else{
          dispatch({
            type: TYPES.GET_PR_FILTER_FAILURE,
            error: "API request failed",
          })
        }
      } catch (error) {
        dispatch({
          type: TYPES.GET_PR_FILTER_FAILURE,
          error:error.message,
        })
      }
    }
  };
  
  
export const fetchSinglePrData=(JobId)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_SINGLE_PR_LOADING
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      
        const response = await axios.get(`${BaseUrl}${GET_VENDOR_DASHBOARD_SINGLE_PR}?JobId=${JobId}`, {
          headers,
        });
      if (response.status === 200){
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_SINGLE_PR_SUCCESS,
          payload: response.data.data,
        })
      }
      else{
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_SINGLE_PR_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_SINGLE_PR_FAILURE,
        error:error.message,
      })
    }
  }
};
