// utils
const currencyData = [
  { label: "THB", value: "THB" },
  { label: "USD", value: "USD" },
  { label: "GBP", value: "GBP" },
  { label: "EUR", value: "EUR" },
  { label: "JPY", value: "JPY" },
  { label: "HKD", value: "HKD" },
  { label: "MYR", value: "MYR" },
  { label: "SGD", value: "SGD" },
  { label: "BND", value: "BND" },
  { label: "CNY", value: "CNY" },
  { label: "IDR", value: "IDR" },
  { label: "MMK", value: "MMK" },
  { label: "INR", value: "INR" },
  { label: "KRW", value: "KRW" },
  { label: "LAK", value: "LAK" },
  { label: "PHP", value: "PHP" },
  { label: "TWD", value: "TWD" },
  { label: "NZD", value: "NZD" },
  { label: "CHF", value: "CHF" },
  { label: "DKK", value: "DKK" },
  { label: "NOK", value: "NOK" },
  { label: "SEK", value: "SEK" },
  { label: "CAD", value: "CAD" },
  { label: "RUB", value: "RUB" },
  { label: "VND", value: "VND" },
  { label: "ZAR", value: "ZAR" },
  { label: "AED", value: "AED" },
  { label: "BHD", value: "BHD" },
  { label: "OMR", value: "OMR" },
  { label: "QAR", value: "QAR" },
  { label: "SAR", value: "SAR" },
];

const bankName = [
  {
    label: "BANGKOK BANK PUBLIC COMPANY LTD. (Thailand) -0020000",
    value: "BANGKOK BANK PUBLIC COMPANY LTD. (Thailand) -0020000",
  },
  {
    label: "KASIKORNBANK PUBLIC COMPANY LIMITED (Thailand) -0040000",
    value: "KASIKORNBANK PUBLIC COMPANY LIMITED (Thailand) -0040000",
  },
  {
    label: "THE ROYAL BANK OF SCOTLAND N.V. (United Kingdom) -0050001",
    value: "THE ROYAL BANK OF SCOTLAND N.V. (United Kingdom) -0050001",
  },
  {
    label: "KRUNG THAI BANK PUBLIC COMPANY LTD. (Thailand) -0060000",
    value: "KRUNG THAI BANK PUBLIC COMPANY LTD. (Thailand) -0060000",
  },
  {
    label: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION (United States of America) -0080001",
    value: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION (United States of America) -0080001",
  },
  {
    label: "TMBTHANACHART BANK PUBLIC COMPANY LIMITED (Thailand) -0110000",
    value: "TMBTHANACHART BANK PUBLIC COMPANY LIMITED (Thailand) -0110000",
  },
  {
    label: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD. (Thailand) -0140000",
    value: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD. (Thailand) -0140000",
  },
  {
    label: "CITIBANK, N.A. (United States of America) -0170001",
    value: "CITIBANK, N.A. (United States of America) -0170001",
  },
  {
    label: "SUMITOMO MITSUI BANKING CORPORATION (Japan) -0180001",
    value: "SUMITOMO MITSUI BANKING CORPORATION (Japan) -0180001",
  },
  {
    label: "STANDARD CHARTERED BANK (THAI) PUBLIC CO., LTD. (Thailand) -0200000",
    value: "STANDARD CHARTERED BANK (THAI) PUBLIC CO., LTD. (Thailand) -0200000",
  },
  {
    label: "CIMB BANKTHAI PUBLIC COMPANY LIMITED (Thailand) -0220000",
    value: "CIMB BANKTHAI PUBLIC COMPANY LIMITED (Thailand) -0220000",
  },
  {
    label: "UNITED OVERSEAS BANK (THAI) PUBLIC CO., LTD. (Thailand) -0240000",
    value: "UNITED OVERSEAS BANK (THAI) PUBLIC CO., LTD. (Thailand) -0240000",
  },
  {
    label: "BANK OF AYUDHAYA PUBLIC COMPANY LTD. (Thailand) -0250000",
    value: "BANK OF AYUDHAYA PUBLIC COMPANY LTD. (Thailand) -0250000",
  },
  {
    label: "MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED (Taiwan) -0260001",
    value: "MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED (Taiwan) -0260001",
  },
  {
    label: "BANK OF AMERICA NATIONAL ASSOCIATION (United States of America) -0270001",
    value: "BANK OF AMERICA NATIONAL ASSOCIATION (United States of America) -0270001",
  },
  {
    label: "GOVERNMENT SAVING BANK (Thailand) -030---",
    value: "GOVERNMENT SAVING BANK (Thailand) -030---",
  },
  {
    label: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (Hong Kong and Shanghai) -0310001",
    value: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (Hong Kong and Shanghai) -0310001",
  },
  {
    label: "DEUTSCHE BANK AG. (Germany) -0320001",
    value: "DEUTSCHE BANK AG. (Germany) -0320001",
  },
  {
    label: "GOVERNMENT HOUSING BANK (Thailand) -033---",
    value: "GOVERNMENT HOUSING BANK (Thailand) -033---",
  },
  {
    label: "BANK FOR AGRICULTURAL AND AGRICULTURAL CO-OPERATIVES (Thailand) -0340001",
    value: "BANK FOR AGRICULTURAL AND AGRICULTURAL CO-OPERATIVES (Thailand) -0340001",
  },
  {
    label: "MIZUHO BANK, LTD. BANGKOK BRANCH (Japan) -0390001",
    value: "MIZUHO BANK, LTD. BANGKOK BRANCH (Japan) -0390001",
  },
  {
    label: "BNP PARIBAS, BANGKOK BRANCH (France) -0450010",
    value: "BNP PARIBAS, BANGKOK BRANCH (France) -0450010",
  },
  {
    label: "BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED (China) -052---",
    value: "BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED (China) -052---",
  },
  {
    label: "ISLAMIC BANK OF THAILAND (Thailand) -0660001",
    value: "ISLAMIC BANK OF THAILAND (Thailand) -0660001",
  },
  {
    label: "TISCO BANK PUBLIC COMPANY LIMITED (Thailand) -067---",
    value: "TISCO BANK PUBLIC COMPANY LIMITED (Thailand) -067---",
  },
  {
    label: "KIATNAKIN BANK PUBLIC COMPANY LIMITED (Thailand) -069---",
    value: "KIATNAKIN BANK PUBLIC COMPANY LIMITED (Thailand) -069---",
  },
  {
    label: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PCL (China) -070---",
    value: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PCL (China) -070---",
  },
  {
    label: "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED (Thailand) -0710001",
    value: "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED (Thailand) -0710001",
  },
  {
    label: "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED (Thailand) -0730001",
    value: "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED (Thailand) -0730001",
  },
  {
    label: "ANZ BANK (THAI) PUBLIC CO. LTD. (Australia and New Zealand) -0790001",
    value: "ANZ BANK (THAI) PUBLIC CO. LTD. (Australia and New Zealand) -0790001",
  },
  {
    label: "SUMITOMO MITSUI TRUST BANK (TH) PCL (Japan) -0800001",
    value: "SUMITOMO MITSUI TRUST BANK (TH) PCL (Japan) -0800001",
  },
  {
    label: "OTHER, please specify. (OTH) – 999---",
    value: "OTHER, please specify. (OTH) – 999---",
  },
];


const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
  }
};

function convertStringToArray(str) {

  if (str && str.length > 0) {
    if (str.includes(",")) {
      return str.split(",");
    } else {
      return [str];
    }
  } else {
    return [str];
  }
}
const subCategoryOptions = [
  { label: "ELECTRICAL", value: "ELECTRICAL" },
  { label: "INSTRUMENT", value: "INSTRUMENT" },
  { label: "MECHANICAL", value: "MECHANICAL" },
  {
    label: "PIPING & FITTINGS",
    value: "PIPING & FITTINGS",
  },
  {
    label: "INSTRUMENT SERVICE",
    value: "INSTRUMENT SERVICE",
  },
  {
    label: "ELECTRICAL SERVICE",
    value: "ELECTRICAL SERVICE",
  },
  { label: "CIVIL", value: "CIVIL" },
  {
    label: "HEALTH-ENVI SERVICES",
    value: "HEALTH-ENVI SERVICES",
  },
  {
    label: "INSPECTION SERVICE",
    value: "INSPECTION SERVICE",
  },
  {
    label: "MECHANICAL SERVICE",
    value: "MECHANICAL SERVICE",
  },
  {
    label: "PIPING SERVICES",
    value: "PIPING SERVICES",
  },
  {
    label: "LAB&CHEMICAL GOODS",
    value: "LAB&CHEMICAL GOODS",
  },
  { label: "OFFICE GOODS", value: "OFFICE GOODS" },
  {
    label: "SAFETY&PROTECTION GOODS",
    value: "SAFETY&PROTECTION GOODS",
  },
  { label: "IT GOODS", value: "IT GOODS" },
  { label: "CHEMICALS", value: "CHEMICALS" },
  {
    label: "INDUSTRIAL GAS",
    value: "INDUSTRIAL GAS",
  },
  { label: "PACKAGING", value: "PACKAGING" },
  { label: "Other", value: "Other" },
];

const ppeOptions = [
  { label: "Safety shoes", value: "Safety shoes" },
  { label: "Safety helmet", value: "Safety helmet" },
  { label: "Safety Glassed/Goggle", value: "Safety Glassed/Goggle" },
  { label: "Chemical Gloves", value: "Chemical Gloves" },
  { label: "Long sleeves shirt", value: "Long sleeves shirt" },
  { label: "Half Mask & Respirator", value: "Half Mask & Respirator" },
  { label: "Other. please specify", value: "Other. please specify" },
];

const isValidNumber = (value) => /^[+-]?\d*\.?\d*$/.test(value);

const handleOnWheel = (event) => {
  event.currentTarget.blur();
};

const formatNumberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const vendorFollowCounts = (data) =>{
  let status = ["delivering", "delivered", "delayed", "penalty_reject", "penalty_confirm"]
  let counts = {
    total : 0
  }
  if(data && data?.length){
    data?.map((item)=>{
      counts[`${status[Number(item?.VmsStatus)-1]}`] = item?.count
      counts.total = counts.total + Number(item.count)
    })
   return counts
  }
    return counts
}
//function to generate date in correct format 
const DateGenerator = (data) => {
  if(!data){
    return null;
  }
 const date =new Date(data)
 return date.toLocaleDateString('en-GB');
}
const numberToWords=(number)=> {
  const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const thousands = ["", "Thousand", "Million", "Billion", "Trillion"];

  // Split number into integer and decimal parts
  
  let numarr = number?.toString().split('.') || [];
  let integerPart = numarr?.[0] || 0
  let  decimalPart = numarr?.[1] || 0
  integerPart = parseInt(integerPart || "0");
  decimalPart = decimalPart ? decimalPart.substring(0, 2) : ""; // Limit to two decimal places

  if (integerPart === 0 && decimalPart === "") return "Zero";

  let word = '';
  let thousandCounter = 0;

  // Convert integer part to words
  while (integerPart > 0) {
      let num = integerPart % 1000;
      if (num !== 0) {
          let str = '';
          if (num % 100 < 20 && num % 100 > 10) {
              str = teens[num % 10] || ones[num % 10];
              num = Math.floor(num / 10);
          } else {
              str = ones[num % 10];
              num = Math.floor(num / 10);
              str = tens[num % 10] + (str ? " " + str : "");
              num = Math.floor(num / 10);
          }
          if (num > 0) {
              str = ones[num] + " Hundred" + (str ? " " + str : "");
          }
          word = str + " " + thousands[thousandCounter] + (word ? " " + word : "");
      }
      integerPart = Math.floor(integerPart / 1000);
      thousandCounter++;
  }

  
  if (decimalPart) {
      let decimalValue = parseInt(decimalPart);
      if (decimalValue > 0) {
          word += " and " + convertDecimalToWords(decimalValue);
      }
  }

  return word.trim();
}
function convertDecimalToWords(decimal) {
  const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

  let word = '';
  let tensValue = Math.floor(decimal / 10);
  let onesValue = decimal % 10;

  // Handle decimal values from 01 to 99
  if (tensValue === 0 && onesValue > 0) {
      word += ones[onesValue];
  } else if (tensValue === 1 && onesValue > 0) {
      word += teens[onesValue];
  } else {
      if (tensValue > 0) {
          word += tens[tensValue] + " ";
      }
      if (onesValue > 0) {
          word += ones[onesValue];
      }
  }
  
  return word.trim() + " Hundredths"; // Use "Hundredths" for decimal parts
}


const deleteObjectWithUrl = (arr) => {
  if(arr && arr?.length > 0){
    return arr.filter(item => !item.hasOwnProperty('url'));
  }
  return []
}
const formatValue = (num) => {
  let numarr = num?.toFixed(2)?.split(".") || [];
  const integerPart = numarr?.[0] || 0
  const  decimalPart = numarr?.[1] || 0
  return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (decimalPart ? `.${decimalPart}` : "");
};
const formatNumberWithCommasandDecimals = (value) => {
  if (typeof value === "number") {
    return formatValue(value);
  } else if (typeof value === "string") {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return formatValue(parsedValue);
    }
  }
  return value;
};

const formatNumber = (numberString) => {
  if (!numberString || isNaN(Number(numberString))) {
    return 0;
  }
  const integerPart = parseInt(numberString, 10);
  return integerPart.toLocaleString();
};
const compareBiddingDate=(biddingDate)=>{
  const parsedBiddingDate = new Date(biddingDate);
  if (isNaN(parsedBiddingDate.getTime())) {
    return false; 
  }
  const biddingDateOnly = parsedBiddingDate.setHours(0, 0, 0, 0);
  const currentDateOnly = new Date().setHours(0, 0, 0, 0);
  return currentDateOnly <= biddingDateOnly;
}

const getFileExtension = (contentType) => {
  const allowedTypes = {
    'application/pdf': '.pdf',
    'application/msword': '.doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    'application/vnd.ms-powerpoint': '.ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
    'text/csv': '.csv',
    'image/jpeg': '.jpg',
    'image/jpg': '.jpg',
    'image/png': '.png',
    'image/bmp': '.bmp',
    'image/gif': '.gif'
  };

  return allowedTypes[contentType] || '.bin';
};

module.exports = {
  deleteObjectWithUrl,
  currencyData,
  bankName,
  convertStringToArray,
  handleKeyDown,
  isValidNumber,
  handleOnWheel,
  subCategoryOptions,
  formatNumberWithCommas,
  ppeOptions,
  vendorFollowCounts,
  DateGenerator,
  formatNumberWithCommasandDecimals,
  formatNumber,
  numberToWords,
  compareBiddingDate,
  getFileExtension,
};
