import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import UploadImage from "../../Assets/Svg/drop-zone.svg";
import CancelIcon from "../../Assets/Images/closeIcon.svg";
import PDFImage from "../../Assets/Svg/pdf-icon.svg";
import questionIcon from "../../Assets/Images/questionmarkIcon.svg";
import "./DropZone.css";
import { useDispatch, useSelector } from "react-redux";
import {  deletePrJobQuotations, fetchPrJobQuotations } from "../../Store/Actions/vendor_dashboard";
import PageLoader from "../../Models/Pageloader/PageLoader";
import { toast } from "react-toastify";

  
const DropZone = ({JobId,onSubmit,uploadEnable=false}) => {
  
  const [myFiles, setMyFiles] = useState([]);
  const dispatch = useDispatch();
  const { quotations, quotationLoading, quotationError } = useSelector(
    (state) => state.VendorDashboardReducer
  );

  const onDrop = useCallback((acceptedFiles) => {
    const maxFileSize=10*1024*1024;
    const allowedFileTypes = [
      "application/pdf", 
      "application/msword", 
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
      "application/vnd.ms-powerpoint", 
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", 
      "text/csv", 
      "image/jpeg", 
      "image/jpg", 
      "image/png", 
      "image/bmp", 
      "image/gif",
    ];


    const validFiles = acceptedFiles.filter(
      (file) => allowedFileTypes.includes(file.type) && file.size <= maxFileSize
    );
    const invalidFiles = acceptedFiles.filter(
      (file) => !allowedFileTypes.includes(file.type) || file.size > maxFileSize
    );
    if (invalidFiles.length > 0) {
      invalidFiles.forEach((file) => {
        if (!allowedFileTypes.includes(file.type)) {
          toast.error(`File type not supported: ${file.name}`, {
            position: "top-right",
            autoClose: 5000,
          });
        } else if (file.size > maxFileSize) {
          toast.error(
            `File size too large (${(file.size / 1024 / 1024).toFixed(2)} MB): ${file.name}`,
            {
              position: "top-right",
              autoClose: 5000,
            }
          );
        }
      });
    }
   
    if (validFiles.length < acceptedFiles.length) {
      // Handle non-supported files here if necessary
    } else {
      setMyFiles(prevSelectedFiles => [...prevSelectedFiles, ...validFiles]);
    }
  }, []);
  onSubmit(myFiles);
  useEffect(() => {
    if (quotations?.QuotationInfo?.QtAttachments) {
      setMyFiles(quotations?.QuotationInfo?.QtAttachments?.map(attachment => ({
        path: attachment?.AttachmentName|| "",
        url: attachment?.AttachmentFileUrl || "",
        JobQuotationId: attachment?.JobQuotationId || "",
        QtAttachmentId:attachment?.QtAttachmentId || "",

      })));
    }
    else{
      setMyFiles([])
    }
  }, [quotations]);
  useEffect(() => {
    dispatch(fetchPrJobQuotations(JobId));
  }, [dispatch, JobId]);

  
  const removeFile = (file) => async () => {
   
    const result = window.confirm("Are you sure you want to delete this file?");
    if (result) {
      if(file?.url){
       await dispatch(deletePrJobQuotations(file.JobQuotationId, file.QtAttachmentId));
      setMyFiles(prevFiles => prevFiles.filter(f => f.QtAttachmentId !== file.QtAttachmentId));
      }
      else{
        setMyFiles(prevFiles => prevFiles.filter(f => f.path !== file.path));
      }
      
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const files = myFiles?.map((file) => (
    <div className="uploaded-document-show my-2">
      <div className="d-flex align-items-center justify-content-center ">
        <div>
          <img
            src={PDFImage}
            alt=""
            style={{ width: "24px", height: "30px" }}
          />
        </div>
        <div className="text-center px-3">
        <a 
          href={file.url}
          target="_blank" 
          rel="noopener noreferrer" 
          className="dropzone-file-name"
        >
          <p className="dropzone-file-name" key={file.path}>
            {file.path}
          </p>
          </a>
        </div>
      </div>
      <div onClick={removeFile(file)} style={{ cursor: "pointer" }}>
        <img
          src={CancelIcon}
          alt=""
          style={{ width: "30px", height: "30px" }}
        />
      </div>
    </div>
  ));
  return (
    <div className="py-3">
      {uploadEnable && 
      <>
      <div className="d-flex justify-content-between">
        <h4 className="dropzone-heading">Quotation</h4>
        <img src={questionIcon} alt="" />
      </div>
      <section className="upload-file-dropzone p-3">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ cursor: "pointer" }}
          >
            <img src={UploadImage} alt="" />
          </div>
        </div>
      </section>
      </>
      }
      <div>
      {quotationLoading && <PageLoader/>}
      {files && files?.length>0 && 
      <>
        <div className="drop-zone-document-title-div pt-4">
          <label className="drop-zone-document-title">Document Files</label>
        </div>
        <div className="">{files}
        {/* {quotations.length > 0 ? (
            quotations.map((quotation, index) => (
              <div key={index}>
                <a href={quotation.fileUrl} target="_blank" rel="noopener noreferrer">
                  {quotation.fileName}
                </a>
              </div>
            ))
          ) : (
            <p>No quotations available.</p>
          )} */}
        </div>
        </>
        }
      </div>
    </div>
  );
};

export default DropZone;
