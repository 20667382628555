import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import viewFile from "../../Assets/Images/Group.svg";
import "./PrDetailsUnderJobs.css";
import DropZone from "../../Components/DropZone/DropZone";
import CustomPopup from "../CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";
import { addJobQuotations, createJobQuotations, fetchPrJobAttachments } from "../../Store/Actions/vendor_dashboard";
import PageLoader from "../Pageloader/PageLoader";
import { toast } from "react-toastify";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { compareBiddingDate } from "../../Utils/Utils";

// CardItem component to represent each card
const CardItem = ({ item }) => (
  <div className="pr-combined-detail-card-item">
    {/* Displaying PR_ID */}
    <span className="pr-combined-detail-card-title">PR ID:</span>
    <input
      type="text"
      className="pr-combined-detail-card-content"
      value={item.PrSapDetail?.PR_ID || ''}
      readOnly
      placeholder="PR ID"
    />

    {/* Displaying PR_Description */}
    <span className="pr-combined-detail-card-title">Description:</span>
    <textarea
      className="pr-combined-detail-card-content"
      value={item.PrSapDetail?.PR_description_Long || ''}
      readOnly
      placeholder="Description"
    />

    {/* Displaying PR_QTY */}
    <span className="pr-combined-detail-card-title">Quantity:</span>
    <input
      type="text"
      className="pr-combined-detail-card-content"
      value={item.PrSapDetail?.PR_QTY || ''}
      readOnly
      placeholder="Quantity"
    />

    {/* Displaying PR_Unit */}
    <span className="pr-combined-detail-card-title">Unit:</span>
    <input
      type="text"
      className="pr-combined-detail-card-content"
      value={item.PrSapDetail?.PR_Unit || ''}
      readOnly
      placeholder="Unit"
    />

    {/* Displaying PR_Company */}
    <span className="pr-combined-detail-card-title">Company:</span>
    <input
      type="text"
      className="pr-combined-detail-card-content"
      value={item.PrSapDetail?.PR_Company || ''}
      readOnly
      placeholder="Company"
    />

    {/* Displaying PR_Group */}
    <span className="pr-combined-detail-card-title">Group:</span>
    <input
      type="text"
      className="pr-combined-detail-card-content"
      value={item.PrSapDetail?.PR_Group || ''}
      readOnly
      placeholder="Group"
    />
  </div>
);


const PrDetailsUnderJobs = ({ isOpen, closeModal, formTitle, data,type}) => {
  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const dispatch = useDispatch();
  const {quotations,attachments,attachmentLoading,attachmentError} = useSelector(state => state.VendorDashboardReducer);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadEnable,setUploadEnable]=useState(false);
  const [files,setFiles]=useState([]);
  const [phoneNumber,setPhoneNumber]=useState(null);
  const handleViewAttachments = async () => {
    if(files.length<1){
     toast.error("No attachments")
     return;
    }
    const zip = new JSZip();
    const filess=files;
    try {
      const filePromises = filess.map(url => 
        fetch(url).then(async response => {
          const blob = await response.blob();
          const filename = decodeURIComponent(url.split('/').pop());
          return { blob, filename };
        })
      );
      const downloadedFiles = await Promise.all(filePromises);
      downloadedFiles?.forEach(({ blob, filename }) => {
        zip.file(filename, blob);
      });
     // Generate the zip file
     const zipContent = await zip.generateAsync({ type: 'blob' });
 
     // Trigger the download
     saveAs(zipContent, 'attachments.zip');
   } catch (error) {
    toast.error(error?.message || "Something went wrong!")
   }
 };

 const checkStatus=()=>{
  if(type===3){
    switch (data?.jobVendorStatus){
      case 0:
        return true;
      default:
        return false;
    }
  }
  else if(type===0){
    return true;
  }
  else if(type===1 || type===2){
    return false;
  }
  else{
    switch (data?.jobVendorStatus){
      case 0:
        return true;
      default:
        return false;
    }
  }
 }
useEffect(()=>{
  if(data?.JobId){
    dispatch(fetchPrJobAttachments(data?.JobId));
  }
  const status=checkStatus();
  if(status){ 
    const checkDate=compareBiddingDate(data?.BiddingDate);
    setUploadEnable(checkDate);
  }
  else{
    setUploadEnable(false);
  }
},[data])



useEffect(()=>{
  if(attachments?.data?.prAttachments?.length>0 || attachments?.data?.PrJobAttachments?.length>0){
    if(attachments?.data?.prAttachments?.length>0){
      const files=attachments?.data?.prAttachments.split(",")
    setFiles(files)
    }
    if(attachments?.data?.PrJobAttachments?.length>0){
      const newFiles = [];
   attachments?.data?.PrJobAttachments?.forEach(attachment => {
     if(attachment?.JobId===data?.JobId){
      
       const file=attachment?.AttachmentFileUrl;
       if(file){
        newFiles.push(file);
       }
     }
   });
   if (newFiles.length > 0) {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }
    }  
    
  }
  else{
    setFiles([]);
  }
},[attachments,data?.JobId])


  const formattedBiddingDate = formatDateForInput(data?.BiddingDate);

  const { t } = useTranslation();
  
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    
      reset({
        phoneNo: quotations?.QuotationInfo?.phoneNo || "",
      });
    
  }, [quotations, reset]);

  const onSubmit = (formData,e) => {
    if(!uploadEnable){
      return;
    }
    e.preventDefault();
    const phoneNumber=formData?.phoneNo;
    const quotationId=quotations?.QuotationInfo?.JobQuotationId;
    const originalPhone = quotations?.QuotationInfo?.phoneNo?.trim();
    const filteredFiles = (filesToUpload || []).filter(file => !file?.url);
    if (!quotationId) {
      if (filteredFiles.length === 0) {
        toast.error("Please add at least one file before submitting.");
        return;
      }
    } else {
      if (phoneNumber === originalPhone && filteredFiles.length === 0) {
        toast.error("Please add at least one file or change the phone number before submitting.");
        return;
      }
    }
    setPhoneNumber(phoneNumber)
    setShowModal(true);
  }
  const submitQuotation=()=>{
    const quotationId=quotations?.QuotationInfo?.JobQuotationId;
    if (!phoneNumber && !quotationId &&  filesToUpload.length === 0) {
      toast.error("Please add at least one file before submitting.");
      return;
    }  
    const filteredFiles = (filesToUpload || []).filter(file => !file?.url);
    if (filteredFiles.length > 0) {
        if (quotations?.QuotationInfo) {
          dispatch(addJobQuotations(data?.JobId,quotationId,phoneNumber, filteredFiles));
        } else {
          dispatch(createJobQuotations(data?.JobId, filteredFiles,phoneNumber));
        }
      
    } 
    else if(quotationId && phoneNumber !=quotations?.QuotationInfo?.phoneNo){
      dispatch(addJobQuotations(data?.JobId,quotationId,phoneNumber))
    }
  }


  const closePopup = () => {
    setFilesToUpload([]);
    setPhoneNumber(null)
    setShowModal(false);
    
  };
  return (
    <>
          {attachmentLoading && <PageLoader />}

      {showModal && (
        <CustomPopup
          isOpenPopup={showModal}
          closePopup={closePopup}
          title="Are you sure!"
          image={areIcon}
          text={"You want to Apply this Project ?"}
          btn1="Cancel"
          btn2="Confirm"
          successTitle="Done!"
          successText={"Apply Project successfully"}
          successIcon={successIcon}
          btn3="Ok"
          scenarioType="applyProject"
          closePrDetails={closeModal}
          submitAction={submitQuotation}
        />
      )}
      <div className="d-flex justify-content-center">
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          className="pr-details-jobs-user-modal"
          overlayClassName="pr-details-jobs-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="pr-details-jobs-title-div">
            <div className="d-flex">
              {" "}
              <h2 style={{ marginRight: "20px" }}>{formTitle}</h2>
              <div className="pr-details-view-file mx-2 px-1" onClick={handleViewAttachments}>
                <img
                  className="pr-details-view-file-image "
                  src={viewFile}
                  alt=""
                  
                />
                <h5 className="pr-details-view-file-name px-2 pt-2">
                  view attachment file
                  
                </h5>
              </div>
            </div>

            <img
              src={closeIcon}
              alt=""
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <br />
            <div className="pr-details-pr-title" style={{ width: "100%" }}>
              <div className="pr-details-date-main mb-3">
                <div className="pr-details-bidd-deli-text">
                  <label htmlFor="BiddingEnd">{t("Bidding End")}</label>
                  <br />
                  <input type="date" value={formattedBiddingDate} readOnly placeholder="Select a Date" />
                </div>
                <div className="pr-details-bidd-deli-text">
                  <label>{t("Buyer")}</label>
                  <input type="text" value={data?.BuyerName}  readOnly placeholder="Pattarapoom L." />
                </div>
              </div>

              <label htmlFor="PR Title">{t("PR Title")}</label>
              <br />
              <input type="text" value={data?.PrTitle}  readOnly id="PR Title" placeholder="" />
            </div>
            <br />
            <div className="pr-details-jobs-main-scroll">
              <div className="pr-details-jobs-main-container">
                <div className="pr-details-jobs-header">
                  {t("PR Combined Detail")}
                </div>
                {data?.JobPrDetails.map((item, index) => (
                  <div className="pr-details-jobs-card-qtn" key={index}>
                    
                    <CardItem item={item} key={index}/>
                  </div>
                ))} 
              </div>
            </div>

            {/* note */}
            <div className="quotation-pr-title">
              <span className="quotation-pr-note-text">{t("Note")}</span>
              <div className="pr-note-textarea">
                <textarea rows="1" value={data?.Note}  readOnly cols="130" placeholder="I"/>
              </div>
            </div>
            <br />

            <DropZone JobId={data?.JobId} onSubmit={(files) => setFilesToUpload(files)} uploadEnable={uploadEnable}/>
{uploadEnable && 
            <div className="pr-details-pr-title" style={{ width: "100%" }}>
              <label htmlFor="Phone Number">{t("Phone Number")}<span className="staricon"> *</span></label>
              <br />
              <input type="text" id="Phone Number" defaultValue={quotations?.QuotationInfo?.phoneNo || ''}
              {...register("phoneNo", {
              })}/>
            </div>
          }
            <div className="pt-3 create-btns-div">
              <button
                type="button"
                className="pr-details-jobs-submit-btn pr-details-jobs-cancel-btn"
                onClick={closeModal}
              >
                Cancel
              </button>

              <button type="submit" className="pr-details-jobs-submit-btn">
                Submit
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default PrDetailsUnderJobs;
