import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { GET_VENDOR_DASHBOARD_STATS,GET_VENDOR_DASHBOARD_PR_LIST, GET_VENDOR_DASHBOARD_SINGLE_PR, GET_VENDOR_DASHBOARD_PO_STATS, GET_VENDOR_DASHBOARD_PR_STATS, GET_VENDOR_DASHBOARD_PR_DATA_BY_FILTER, GET_VENDOR_DASHBOARD_PO_DATA, GET_VENDOR_DASHBOARD_PO_DATA_BY_FILTER, GET_VENDOR_DASHBOARD_PR_JOB_ATTACHMENT, GET_VENDOR_DASHBOARD_QUOTATION, POST_VENDOR_DASHBOARD_CREATE_QUOTATION, DELETE_VENDOR_DASHBOARD_QUOATATION_ATTACHMENT, POST_VENDOR_DASHBOARD_ADDMORE_QUOTATION, POST_VENDOR_DASHBOARD_UPLOAD_PO_DOC, DELETE_VENDOR_DASHBOARD_PO_DOC, PATCH_VENDOR_DASHBOARD_UPDATE_PO_STATUS } from "../../ApiEndPoints/ApiEndPoints";
import axios from "axios";
import TYPES from "../constants";
import { toast } from "react-toastify";



export const fetchVendorStats=()=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_STATS_LOADING
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_VENDOR_DASHBOARD_STATS}`, { headers });
      
      if (response.status === 200){
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_STATS_SUCCESS,
          payload: response.data.data,
        })
      }
      else{
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_STATS_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_STATS_FAILURE,
        error:error.message,
      })
    }
  }
};

export const fetchPrList=(page=1,pageSize=5,orderBy='updatedAt',orderDir='DESC')=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PR_LIST_LOADING
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_VENDOR_DASHBOARD_PR_LIST}?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderDir=${orderDir}`, { headers });
      
      if (response.status === 200){
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_LIST_SUCCESS,
          payload: response.data.data,
        })
      }
      else{
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_LIST_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PR_LIST_FAILURE,
        error:error.message,
      })
    }
  }
};


export const fetchPoList=(page=1,pageSize=10)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PO_LIST_LOADING
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_VENDOR_DASHBOARD_PO_DATA}?page=${page}&pageSize=${pageSize}&orderBy=updatedAt&orderDir=DESC`, { headers });
      
      if (response.status === 200){
       
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PO_LIST_SUCCESS,
          payload: response.data.data,
        })
      }
      else{
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PO_LIST_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PO_LIST_FAILURE,
        error:error.message,
      })
    }
  }
}; 
export const fetchPrStatus=()=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PR_STATS_LOADING
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_VENDOR_DASHBOARD_PR_STATS}`, { headers });
      
      if (response.status === 200){
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_STATS_SUCCESS,
          payload: response.data.data,
        })
      }
      else{
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_STATS_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PR_STATS_FAILURE,
        error:error.message,
      })
    }
  }
};
export const fetchPoStatus=()=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PO_STATS_LOADING
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_VENDOR_DASHBOARD_PO_STATS}`, { headers });
      
      if (response.status === 200){
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PO_STATS_SUCCESS,
          payload: response.data.data,
        })
      }
      else{
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PO_STATS_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PO_STATS_FAILURE,
        error:error.message,
      })
    }
  }
};
export const fetchPrDataByFilter=(page = 1, pageSize = 5, orderBy = 'updatedAt', orderDir = 'ASC', filterStatus = 2)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PRDATA_LOADING
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_VENDOR_DASHBOARD_PR_DATA_BY_FILTER}`,{headers,
        params: { page, pageSize, orderBy, orderDir, filterStatus },
      });
      if (response.status === 200){
     
        dispatch({
        
          type: TYPES.VENDOR_DASHBOARD_PRDATA_SUCCESS,
          payload: response.data.data,
        })
      }
      else{
       
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PRDATA_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
     
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PRDATA_FAILURE,
        error:error.message,
      })
    }
  }
};
export const fetchPoDataByFilter=(page = 1, pageSize = 10, orderBy = 'PoDbId', orderDir = 'desc', filterStatus)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PODATA_LOADING
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const params = { page, pageSize, orderBy, orderDir };
      if (filterStatus !== undefined) {
        params.filterStatus = filterStatus;
      }
      const response = await axios.get(`${BaseUrl}${GET_VENDOR_DASHBOARD_PO_DATA_BY_FILTER}`,{headers,
        params,
      });
      if (response.status === 200){
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PODATA_SUCCESS,
          payload: response.data.data,
        })
      }
      else{
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PODATA_FAILURE,
          error: "API request failed",
        })
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PODATA_FAILURE,
        error:error.message,
      })
    }
  }
};


export const fetchPrJobAttachments = (jobId) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PR_ATTACHMENT_LOADING,
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${BaseUrl}${GET_VENDOR_DASHBOARD_PR_JOB_ATTACHMENT}`,
        {
          headers,
          params: { JobId: jobId },
        }
      );

      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_ATTACHMENT_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_ATTACHMENT_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PR_ATTACHMENT_FAILURE,
        error: error.message,
      });
    }
  };
};
export const fetchPrJobQuotations = (jobId) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PR_QUOTATION_LOADING,
    });
  
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${BaseUrl}${GET_VENDOR_DASHBOARD_QUOTATION}`,
        {
          headers,
          params: { JobId: jobId },
        }
      );
      


      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_QUOTATION_SUCCESS,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_QUOTATION_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PR_QUOTATION_FAILURE,
        error: error.message,
      });
    }
  };
};
export const deletePrJobQuotations = (JobQuotationId,QtAttachmentId) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PR_QUOTATION_DELETE_LOADING,
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
     
      const response = await axios.delete(
        `${BaseUrl}${DELETE_VENDOR_DASHBOARD_QUOATATION_ATTACHMENT}`,
        {
          headers,
          params: { JobQuotationId, QtAttachmentId },
        }
      );
      


      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_QUOTATION_DELETE_SUCCESS,
          payload: response.data.data.DeletedAttachment,
        });
      } else {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_QUOTATION_DELETE_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PR_QUOTATION_DELETE_FAILURE,
        error: error.message,
      });
    }
  };
};

export const createJobQuotations = (jobId,files,phoneNo)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PR_CREATE_QUOTATION_LOADING,
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
      formData.append('JobId', jobId);
      if(files){
      
        files.forEach((file)=>{
          formData.append('AttachmentFile', file);
        }) 
      }
      formData.append('phoneNo',phoneNo)

      const response = await axios.post(
        `${BaseUrl}${POST_VENDOR_DASHBOARD_CREATE_QUOTATION}`,
        formData,
        {
          headers
        }
      );
      

      if (response.status === 200) {
        toast.success("Quotation added successfully")
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_CREATE_QUOTATION_SUCCESS,
          payload: response?.data.data?.QtAttachments,
        });
      }
      else {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_CREATE_QUOTATION_FAILURE,
          error: "API request failed",
        });
      }

    }
    catch(error){
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PR_CREATE_QUOTATION_FAILURE,
        error: error.message,
      });
    }
  }
}


export const addJobQuotations = (JobId,JobQuotationId,phoneNo,files)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PR_ADD_QUOTATION_LOADING,
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      };
      
      const formData = new FormData();
      formData.append('JobId', JobId);
      
      if(files){
      
        files.forEach((file)=>{
          formData.append('AttachmentFile', file);
        }) 
      }
      
      formData.append('JobQuotationId',JobQuotationId)
      formData.append('phoneNo',phoneNo)
      
      const response = await axios.post(
        `${BaseUrl}${POST_VENDOR_DASHBOARD_ADDMORE_QUOTATION}`,
        formData,
        {
          headers
        }
      );
      

      if (response.status === 200) {
        toast.success("Quotation added successfully")
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_ADD_QUOTATION_SUCCESS,
          payload: response.data.data.QtAttachments,
        });
      }
      else {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_PR_ADD_QUOTATION_FAILURE,
          error: "API request failed",
        });
      }

    }
    catch(error){
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_PR_ADD_QUOTATION_FAILURE,
        error: error.message,
      });
    }
  }
}




//upload po doc
export const uploadPoDoc = (id,file)=>{
  return async (dispatch)=>{
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_UPLOAD_PO_DOC_LOADING,
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
      formData.append('attachments', file);

      const response = await axios.post(
        `${BaseUrl}${POST_VENDOR_DASHBOARD_UPLOAD_PO_DOC}/${id}`,
        formData,
        {
          headers
        }
      );
     
      if (response.status === 201) {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_UPLOAD_PO_DOC_SUCCESS,
          payload: response?.data?.data,
        });
        toast.success("File uploaded successfully")
      }
      else {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_UPLOAD_PO_DOC_FAILURE,
          error: "API request failed",
        });
      }

    }
    catch(error){
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_UPLOAD_PO_DOC_FAILURE,
        error: error.message,
      });
    }
  }
}
//delete po doc

export const deletePoDoc = (id) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_DELETE_PO_DOC_LOADING,
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
     
      const response = await axios.delete(
        `${BaseUrl}${DELETE_VENDOR_DASHBOARD_PO_DOC}/${id}`,
        {
          headers,
        }
      );
      if (response?.data?.status == 200) {
        toast.success(response?.data?.message);
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_DELETE_PO_DOC_SUCCESS,
          payload: id,
        });
        
        
      } else {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_DELETE_PO_DOC_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_DELETE_PO_DOC_FAILURE,
        error: error.message,
      });
    }
  };
};

export const updatePoDoc = (poDoc) => {
  return (dispatch) => {
    
    dispatch({
      type: TYPES.VENDOR_DASHBOARD_PO_DOC_SUCCESS,
      payload: poDoc,
    });
  };
};

export const updateVendorStatus = (id) => {
  return async (dispatch) => {
    dispatch({
        type : TYPES.VENDOR_DASHBOARD_UPDATE_PO_STATUS_LOADING
    });
    try {
      
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.patch(`${BaseUrl}${PATCH_VENDOR_DASHBOARD_UPDATE_PO_STATUS}/${id}`,{} ,{
        headers
      });
      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_UPDATE_PO_STATUS_SUCCESS,
        });
        
        toast.success("Updated")
      } else {
        dispatch({
          type: TYPES.VENDOR_DASHBOARD_UPDATE_PO_STATUS_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
      dispatch({
        type: TYPES.VENDOR_DASHBOARD_UPDATE_PO_STATUS_FAILURE,
        error: error.message,
      });
    }
  };
};

export const clearVendorDashboard = () => {
  return {
    type: TYPES.VENDOR_DASHBOARD_PRDATA_CLEAR,
  };
};

