import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../CustomPagination/CustomPagination";
import TuneIcon from "@mui/icons-material/Tune";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrCount, fetchPrFilterDropDown, fetchPrListOpen } from "../../../Store/Actions/vendorJobsAction";
import { DateGenerator } from "../../../Utils/Utils";
import SingleJobExpandedTable from "./SingleJobExpandableTable";
import SinglePrDetailsUnderJobs from "../../../Models/PrDetailsUnderJobs/SinglePrDetailsUnderJobs";
import PageLoader from "../../../Models/Pageloader/PageLoader";
import JobFilterPopup from "../JobsFilter";

const JobsTable = ({searchQuery}) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("vms_vendor_token");
  const dispatch = useDispatch();
  const {
    prCount,prList,loading,error,filterList
  } = useSelector((state) => state.VendorJobsReducer || {});
  const [data,setData]=useState({
    all:[],
    goods:[],
    service:[],
    project:[],
  })
  const [filteredData,setFilteredData]=useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowData,setSelectedRowData]=useState({});
  // const [data, setData] = useState(tableData);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState(0);
  const navigate = useNavigate();
  const [enableFilter, setEnableFilter] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [count,setCount]=useState(prCount?.goodsCount+ prCount?.projectCount + prCount?.serviceCount ||0);
  const[orderBy,setOrderBy]=useState("updatedAt");
  const [orderDir,setOrderDir]=useState("DESC")
  const [appliedFilters, setAppliedFilters] = useState([]);
  // Callback function to receive selected categories from JobsFilter
  const handleCategoryChange = useCallback((selectedCategories) => {
    setSelectedCategories(selectedCategories);
  }, []);
  useEffect(()=>{
    dispatch(fetchPrCount());
    dispatch(fetchPrFilterDropDown())
  },[])
  useEffect(()=>{
    
      dispatch(fetchPrListOpen(currentPage,itemsPerPage,orderBy,orderDir,type,appliedFilters,searchQuery));
  },[currentPage,itemsPerPage,orderBy,orderDir,type,appliedFilters,searchQuery])

  useEffect(()=>{
    if(type===0){
      setData((prevState)=>({
        ...prevState,
        all:prList?.PrJobList?.Items || []
      }))
    }
    else if(type===1){
      setData((prevState)=>({
        ...prevState,
        goods:prList?.PrJobList?.Items || []
      }))
    }
    else if(type===2){
      setData((prevState)=>({
        ...prevState,
        service:prList?.PrJobList?.Items || []
      }))
    }
    else if(type===3){
      setData((prevState)=>({
        ...prevState,
        project:prList?.PrJobList?.Items || []
      }))
    }
    setCount(prList?.PrJobList?.TotalCount)
  },[prList,type,count])
  
  useEffect(()=>{
    type===0?setFilteredData(data?.all|| []):type===1?setFilteredData(data?.goods|| []):type===2?setFilteredData(data?.service|| []):type===3?setFilteredData(data?.project|| []):setFilteredData([])
  },[data,type])
  const handleTypeSelect = (selectedKey) => setType(parseInt(selectedKey));
  const [jobFilterModalOpen,setJobFilterModalOpen]=useState(false);

  const JobFilterOpenModal = () => {
    setJobFilterModalOpen(true);
  };
  const JobFilterCloseModal = () => {
    setJobFilterModalOpen(false);
  }; 
  const toggleFilter = () => {
    setJobFilterModalOpen(!jobFilterModalOpen)
  };

  const handleApplyFilter = (selectedFilters) => {
    setAppliedFilters(selectedFilters);
    
  };

  const ExpandedComponent = ({ data }) => (
    <pre>
      <SingleJobExpandedTable data={data} />
    </pre>
  );

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#003595",
        color: "white",
        fontSize: "15px",
      },
    },
  };

  const columns = [
    {
      name: t("Location"),
      selector: (result) => result?.PR_Company || "N/A",
      sortable: true,
      width: "8%",
    },
    {
      id:"PR_ID",
      name: t("PR No."),
      selector:(result) => result?.PR_ID || "Combined (1)",
      sortable: true,
      width: "10%",
    },
    {
      name: t("PR Title"),
      selector: (result) => result?.PR_description_Short  || "Combined (1)",
      cell: (result) => (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ gap: "5px" }}
        >
          <p>
         { result?.PR_description_Short  || "Combined (1)"}
          </p>
        </div>
      ),
      sortable: true,
      width: "20%",
    },
    {
      name: t("Job Title"),
      cell: (result) => (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ gap: "5px" }}
        >
          <p>
         { result?.PrTitle }
          </p>
        </div>
      ),
      sortable: true,
      // width: "15%",
    },

    {
      name: t("Job Create"),
      selector: (row) => DateGenerator(row?.createdAt) || "",
      selector: (row) => DateGenerator(row?.createdAt) || "",
      sortable: true,
      // width: "10%",
    },
    {
      name: t("Bidding End"),
      selector:  (row) => DateGenerator(row?.BiddingDate) || "",
      sortable: true,
      // width: "10%",
    },
    {
      name: t("Delivery Date"),
      selector: (row) => DateGenerator(row?.DeliveryDate) || "",
      sortable: true,
      // width: "10%",
    },
  ];

  if (token) {
    // Add the "Action" column when the token exists
    columns.push({
      name: t("Action"),
      cell: (result) => (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ gap: "5px" }}
        >
          <button
            className="jobs-view-btn"
            onClick={() => {
              handleViewDetail(result);
            }}
          >
            {t("View Detail")}
          </button>
        </div>
      ),
    });
  }

  const handleViewDetail = (result) => {
    setSelectedRowData(result);
    setIsModalOpen(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
    total: 0,
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    {loading && <PageLoader/>}
      {" "}
     
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="jobs-table-header-div">
                    <div className="jobs-table-head">
                      <Nav activeKey="/home" onSelect={handleTypeSelect}>
                        <Nav.Item>
                          <Nav.Link
                            className={
                              type === 0
                                ? "announcement-table-nav-active"
                                : "announcement-table-nav"
                            }
                            eventKey="0"
                          >
                            All
                            <span
                              className={
                                type === 0
                                  ? "announcement-top-badge-active"
                                  : "announcement-top-badge"
                              }
                              eventKey="0"
                            >
                              {prCount?.goodsCount+ prCount?.projectCount + prCount?.serviceCount ||0}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className={
                              type === 1
                                ? "announcement-table-nav-active"
                                : "announcement-table-nav"
                            }
                            eventKey="1"
                          >
                            {t("Goods")}
                            <span
                              className={
                                type === 1
                                  ? "announcement-top-badge-active"
                                  : "announcement-top-badge"
                              }
                              eventKey="1"
                            >
                              {prCount?.goodsCount||0}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className={
                              type === 2
                                ? "announcement-table-nav-active"
                                : "announcement-table-nav"
                            }
                            eventKey="2"
                          >
                            {t("Service")}
                            <span
                              className={
                                type === 2
                                  ? "announcement-top-badge-active"
                                  : "announcement-top-badge"
                              }
                              eventKey="2"
                            >
                              {prCount?.serviceCount ||0}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className={
                              type === 3
                                ? "announcement-table-nav-active"
                                : "announcement-table-nav"
                            }
                            eventKey="3"
                          >
                            {t("Project")}
                            <span
                              className={
                                type === 3
                                  ? "announcement-top-badge-active"
                                  : "announcement-top-badge"
                              }
                              eventKey="3"
                            >
                             { prCount?.projectCount ||0}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <div className="d-flex justify-content-between align-items-center my-3">
                        <div className="">
                          <button
                            className="filter-button"
                            onClick={toggleFilter}
                          >
                            <TuneIcon /> {t("Filter")}
                          </button>

                          
                          
                        </div>
                        <JobFilterPopup
                              JobFilterIsOpen={jobFilterModalOpen}
                              JobFilterCloseModal={JobFilterCloseModal}
                              onApplyFilter={handleApplyFilter}
                              setJobFilterModalOpen={setJobFilterModalOpen}
                              filterList={filterList}
                            />
                        <div className="px-3" style={{ whiteSpace: "nowrap" }}>
                          <label htmlFor="" style={{ color: "#003595" }}>
                            {t("Show")}
                          </label>
                          <select
                            className="show-select-options px-2 mx-2"
                            value={itemsPerPage}
                            onChange={handleDropdownChange}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="100">100</option>
                          </select>
                          <label>{t("entries")}</label>
                        </div>
                      </div>
                      
                     

                    </div>
                    

                    <DataTable
                      className="jobs-table"
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      pagination={false}
                      noDataComponent="No records to display"
                      expandableRows={false}
                      expandableRowDisabled={(row) => !row}
                      expandableRowExpanded={(row) => row}
                      expandableRowsComponent={ExpandedComponent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p className="job-show-entries">
              {" "}
              Showing {displayedRange.start} to {displayedRange.end} of{" "}
              {count} entries
            </p>
            <CustomPagination
              totalItems={count}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
     
     
      {isModalOpen && (
        <SinglePrDetailsUnderJobs
          isOpen={isModalOpen}
          closeModal={closeModal}
          formTitle="PR detail"
          data={selectedRowData}
          setSelectedRowData={setSelectedRowData}
        />
      )}
    </>
  );
};

export default JobsTable;
