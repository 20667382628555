import React, { useCallback, useState } from "react";
import "./uploadpo.css";
import { useDropzone } from "react-dropzone";
import UploadImage from "../../Assets/Images/Frame878.svg";
import CancelIcon from "../../Assets/Images/closeIcon.svg";
import PDFImage from "../../Assets/Images/Vector-pdf.svg";
import QuestionMarkIcon from "../../Assets/Images/questionmarkIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { deletePoDoc, uploadPoDoc } from "../../Store/Actions/vendor_dashboard";
import { toast } from "react-toastify";

const UploadPO = ({id,myFiles,setMyFiles,data,setNewAttachedFiles,newAttachedFiles}) => {
  const { poDoc, attachmentLoading, attachmentError } = useSelector(
    (state) => state.VendorDashboardReducer
  );
 
  const dispatch = useDispatch();
  const onDrop = useCallback((acceptedFiles) => {
    const maxFileSize=10*1024*1024;
    const allowedFileTypes = [
      "application/pdf", 
      "application/msword", 
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
      "application/vnd.ms-powerpoint", 
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", 
      "text/csv", 
      "image/jpeg", 
      "image/jpg", 
      "image/png", 
      "image/bmp", 
      "image/gif",
    ];
    const validFiles = acceptedFiles.filter(
      (file) => allowedFileTypes.includes(file.type) && file.size <= maxFileSize
    );
    const invalidFiles = acceptedFiles.filter(
      (file) => !allowedFileTypes.includes(file.type) || file.size > maxFileSize
    );
    if (invalidFiles.length > 0) {
      invalidFiles.forEach((file) => {
        if (!allowedFileTypes.includes(file.type)) {
          toast.error(`File type not supported: ${file.name}`, {
            position: "top-right",
            autoClose: 5000,
          });
        } else if (file.size > maxFileSize) {
          toast.error(
            `File size too large (${(file.size / 1024 / 1024).toFixed(2)} MB): ${file.name}`,
            {
              position: "top-right",
              autoClose: 5000,
            }
          );
        }
      });
    }

    if (validFiles.length < acceptedFiles.length) {
      
    } else {
      setNewAttachedFiles(prevSelectedFiles => {
        const updatedFiles = [...prevSelectedFiles, ...validFiles];
        return updatedFiles;
      });
    }
    
  },[myFiles]);

  const removeFile = (file) => async () => {
    const result = window.confirm("Are you sure you want to delete this file?");
    if (result) {
      
      await dispatch(deletePoDoc(file?.id))
      setMyFiles(prevFiles => prevFiles.filter(f => f.attachmentId !== file.attachmentId));
    }
  };
  const removeAttachedFile= (file)=> async ()=>{
    setNewAttachedFiles(prevFiles => prevFiles.filter(f => f.name !== file.name))
  }
  

  const {  getRootProps, getInputProps, open } = useDropzone({
    onDrop,
  });
  

  const files = myFiles.map((file) => (
    
    <div className="upload-po-document-show p-1 my-2">
      <div className="d-flex align-items-center justify-content-center">
       
        <div>
          <img
            src={PDFImage}
            alt=""
            style={{ width: "24px", height: "30px" }}
          />
        </div>
        <a href={`${file.url}`} target="_blank" rel="noopener noreferrer" >
        <div className="text-center px-3">
          <p key={file.path}>{file.path}</p>
        </div>
        </a>
      </div>
      <div onClick={removeFile(file)} style={{ cursor: "pointer" }}>
        <img
          src={CancelIcon}
          alt=""
          style={{ width: "30px", height: "30px" }}
        />
      </div>
    </div>
  ));
  const newFiles = newAttachedFiles.map((file) => (
    
    <div className="upload-po-document-show p-1 my-2">
      <div className="d-flex align-items-center justify-content-center">
       
        <div>
          <img
            src={PDFImage}
            alt=""
            style={{ width: "24px", height: "30px" }}
          />
        </div>
        
        <div className="text-center px-3">
          <p key={file.path}>{file.path}</p>
        </div>
      
      </div>
      <div onClick={removeAttachedFile(file)} style={{ cursor: "pointer" }}>
        <img
          src={CancelIcon}
          alt=""
          style={{ width: "30px", height: "30px" }}
        />
      </div>
    </div>
  ));

  return (
    <>
      <div className="po-upload-main-comp">
        <div className="bgc-po-style-comp">
          <h3 className="upload-po-sub-header py-3">Upload PO File</h3>
          <img src={QuestionMarkIcon} alt="?" className="que-mark-logo" />
        </div>
        <div className="bgc-po-style-comp">
          <section className="upload-po-file p-3">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ cursor: "pointer" }}
              >
                <img src={UploadImage} alt="" />
              </div>
            </div>
          </section>
        </div>

        <div className="bgc-po-style-comp py-3">
          <button
            type="button"
            className="upload-po-image-btn mt-3 p-2"
            onClick={open}
          >
            Select Document File
          </button>
        </div>
      </div>

      <div className="drop-zone-document-title-div pt-4 mx-3">
        <label className="upload-drop-zone-document-title">
          Document Files
        </label>
      </div>
      <div className="po-document-file-name px-4">{files}</div>
      <div className="drop-zone-document-title-div pt-2 mx-3">
        <label className="upload-drop-zone-document-title">
          Attached Files
        </label>
      </div>
      <div className="po-document-file-name px-4">{newFiles}</div>
    </>
  );
};

export default UploadPO;
