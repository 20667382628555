import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import CustomPagination from "../CustomPagination/CustomPagination";
import csvLogo from "../../Assets/Images/csvLogo.png";
import CsvDownloadButton from "react-json-to-csv";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import "./VendorFollowTable.css";
import { useDispatch, useSelector } from "react-redux";
import { getVendorFollowAllPRData } from "../../Store/Actions/vendorFollowAction";
import PageLoader from "../../Models/Pageloader/PageLoader";
import { DateGenerator, formatNumberWithCommasandDecimals } from "../../Utils/Utils";

const VendorFollowTable = (props) => {
  
  
  const [count, setCount] = useState(props.counts.total || 0);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [orderDir, setOrderDir] = useState('DESC');
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [type, setType] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);

  const {vendorFollowData,dataLoading,dataError}= useSelector((state) => state.VendorFollowReducer);
  useEffect(()=>{
    if(type===0){
      dispatch(getVendorFollowAllPRData(currentPage,itemsPerPage,orderBy,orderDir));
    }
    else{
      dispatch(getVendorFollowAllPRData(currentPage,itemsPerPage,orderBy,orderDir,type))
    }
    setCount(vendorFollowData?.TotalCount);
    
    // setCount(data?)
  },[dispatch,currentPage,itemsPerPage,type,orderBy,orderDir])
useEffect(()=>{
  setCount(vendorFollowData?.TotalCount);
},[vendorFollowData])
  useEffect(()=>{
    if(!dataLoading){
      setData(vendorFollowData?.data)
    }
  },[dataLoading])

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#103A5D",
        color: "white",
        fontSize: "12px",
        fontFamily: "Sarabun",
        fontWeight: "400",
        width: "100%",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "12px",
        color: "#103a5d",
        height: "auto",
        fontWeight: "400",
        letterSpacing: "0.5px",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
        fontWeight: "500",
      },
    },
  };
  const columns = [
    {
      id: "PONo",
      name: t("PO No."),
      selector: (row) => row?.poData?.PONumber || "",
      sortable: true,
      width: "11%",
    },
    {
      id: "Topic",
      name: t("Topic"),
      selector: (row) => row?.PrShortText || "",
      width: "10%",
    },
    {
      id: "DeliveryDate",
      name: t("Delivery Date(PO)"),
      selector: (row) => DateGenerator(row?.PrDeliveryDate) || "",
      sortable: true,
      width: "10%",
    },
    {
      id: "Qty",
      name: t("Qty"),
      selector: (row) => row?.PrQTY.toLocaleString() || "",
      width: "14%",
    },
    {
      id: "PRUnit",
      name: t("Unit"),
      selector: (row) => row?.PRUnit || "",
      width: "7%",
    },
    {
      id: "Amount",
      name: t("Amount"),
      selector: (row) => formatNumberWithCommasandDecimals(row?.poData?.POTotalAmount) || "",
      sortable: true,
      width: "9%",
    },
    {
      id: "Currency",
      name: t("Currency"),
      selector: (row) => row?.poData?.POCurrency || "",
      sortable: true,
      width: "10%",
    },
    {
      id: "Status",
      name: t("Status"),
      width: "12%",
      selector: (row) => row?.VmsStatus || "",
      searchable: false,
      cell: (result) => (
        <div
          className={
            result.VmsStatus === 2
              ? "table-status delivered"
              : result.VmsStatus === 3 || result.VmsStatus === 4 || result.VmsStatus === 5
              ? "table-status delayed"
              : result.VmsStatus === 1
              ? "table-status delivering"
              : ""
          }
        >
          {result.VmsStatus === 2
              ? "Delivered"
              : result.VmsStatus === 3 
              ? "Delayed"
              : result.VmsStatus === 1
              ? "Delivering"
              : result.VmsStatus === 4
              ?"Penalty reject" :
              result.VmsStatus === 5
              ?"Penalty Accept"
              : ""
          }
        </div>
      ),
    },
    {
      id: "Action",
      width: "24%",
      name: t("Action"),
      cell: (result) => (
      
        <div className="d-flex">
        
          {result.VmsStatus === 2 && (
            <>
              <button
                className="table-btn"
                onClick={() => {
                  
                  handleViewPr(result?.poData?.PoDbId);
                }}
              >
                {t("View")}
              </button>
            </>
          )}
          {result.VmsStatus === 1 && (
            <button
              className="table-btn"
              onClick={() => {
                handleViewPr(result?.poData?.PoDbId);
              }}
            >
              {t("View")}
            </button>
          )}
          {(result.VmsStatus === 3 ||result.VmsStatus === 4  || result.VmsStatus === 5 )&& (
            <>
              <button
                className="table-btn"
                onClick={() => {
                  handleViewPr(result?.poData?.PoDbId);
                }}
              >
                {t("View")}
              </button>
                  {/* as there is no penalty so i have added this condition */}
            { result?.penalty&& <button className="ven-foll-sp-btn" onClick={() => {
                        props.setOpenClaimPopup(true)
                        props.setId(result?.penalty?.penaltyId)
                      }}>
                {t("Show Penalty")}
              </button>
    }
            </>
          )}
        </div>
      ),
      searchable: false,
    },
  ];

  const handleViewPr = (id) => {
    navigate(`/po-details/${id}`);
  };
  //Penalty reject  popup
  // const [PenaltyRejectModalOpen, setPenaltyRejectIsModalOpen] = useState(false);
  // const PenaltyRejectOpenModal = () => {
  //   setPenaltyRejectIsModalOpen(true);
  // };
  // const PenaltyRejectCloseModal = () => {
  //   setPenaltyRejectIsModalOpen(false);
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleTypeSelect = (selectedKey) => {
    setCurrentPage(1);
    setType(parseInt(selectedKey));
  }


  const headerNames = columns?.map((column) => column.name);
  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
   
  };
  const csvData = (data || []).map((item) =>
    columns.reduce((row, column) => {
      if(column.id==="Status"){
        row[column.name]=item.VmsStatus === 2
        ? "Delivered"
        : item.VmsStatus === 3 
        ? "Delayed"
        : item.VmsStatus === 1
        ? "Delivering"
        : item.VmsStatus === 4
        ?"Penalty reject" :
        item.VmsStatus === 5
        ?"Penalty Accept"
        : ""
      }
      else if (column.id==="Qty"){
        row[column.name]=item?.PrQTY.toLocaleString()
      }
      else{
        row[column.name] = column.selector ? column.selector(item) : item[column.name] || '';

      }
      return row;
    }, {})
  );

  const getCountByStatus = (status) => {
    return data.filter((item) => item.status === status).length;
  };

  return (
    <>
     {dataLoading && <PageLoader/>}
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="vendor-foll-table-navbar">
            <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 0
                      ? "vendor-follow-visit-table-active"
                      : "vendor-follow-visit-table"
                  }
                  eventKey="0"
                >
                  {t("All")}
                  <span
                    className={
                      type === 0
                        ? "vendor-follow-visit-table-badge-active"
                        : "vendor-follow-visit-table-badge"
                    }
                    eventKey="0"
                  >
                    {props?.counts?.total || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 1
                      ? "vendor-follow-visit-table-active"
                      : "vendor-follow-visit-table"
                  }
                  eventKey="1"
                >
                  {t("Delivering")}
                  <span
                    className={
                      type === 1
                        ? "vendor-follow-visit-table-badge-active"
                        : "vendor-follow-visit-table-badge"
                    }
                    eventKey="1"
                  >
                    {props?.counts?.delivering || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 2
                      ? "vendor-follow-visit-table-active"
                      : "vendor-follow-visit-table"
                  }
                  eventKey="2"
                >
                  {t("Delivered")}
                  <span
                    className={
                      type === 2
                        ? "vendor-follow-visit-table-badge-active"
                        : "vendor-follow-visit-table-badge"
                    }
                    eventKey="2"
                  >
                    {props?.counts?.delivered || 0}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === 3
                      ? "vendor-follow-visit-table-active"
                      : "vendor-follow-visit-table"
                  }
                  eventKey="3"
                >
                  {t("Delayed")}
                  <span
                    className={
                      type === 3
                        ? "vendor-follow-visit-table-badge-active"
                        : "vendor-follow-visit-table-badge"
                    }
                    eventKey="3"
                  >
                    {(props?.counts?.delayed || 0) + (props?.counts?.penalty_reject || 0) + (props?.counts?.penalty_confirm || 0)
                    }
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>{t("Show")} </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>100</option>
              </select>{" "}
              <span>{t("entries")}</span>
            </div>
            <CsvDownloadButton
              data={csvData}
              filename="vendor_follow_data.csv"
              headers={headerNames}
              delimiter={","}
              style={{
                backgroundColor: "#fafbff",
                border: "0px solid #a511c0",
                display: "inline-block",
                cursor: "pointer",
                fontSize: "15px",
                fontWeight: "bold",
                padding: "6px 24px",
                textDecoration: "none",
                textShadow: "0px 1px 0px #9b14b3",
              }}
            >
              <img src={csvLogo} alt="" />
            </CsvDownloadButton>
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            data.length ? "um-data-table" : "um-empty-data-table"
          }
        >

          <DataTable
            columns={columns}
            data={data || []}
            customStyles={customStyles}
            pagination={false}
            noDataComponent="No records to display"
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          innerPagination={true}
        />
      </div>
      {/* PenaltyRejectPopup */}
      {/* <PenaltyRejectPopup
        PenaltyRejectIsOpen={PenaltyRejectModalOpen}
        PenaltyRejectFormTitle={"Penalty reject"}
        PenaltyRejectCloseModal={PenaltyRejectCloseModal}
        
      />  */}
    </>
  );
};

export default VendorFollowTable;
