import React, { useEffect, useState } from "react";
import VendorFollowTable from "../../../Components/DataTable/VendorFollowTable";
import "./vendorFollow.css";
import DashBoardLayout from "../../../Layouts/DashboardLayout/DashBoardLayout";
import Popup from "reactjs-popup";
import closeButton from "../../../Assets/Images/closeButton.png";
import penaltyButton from "../../../Assets/Images/penaltyButtonLogo.png";
import areYouSure from "../../../Assets/Images/areYouSureLogo.png";
import successLogo from "../../../Assets/Images/successLogo.png";
import { useTranslation } from "react-i18next";
import closeicon from "../../../Assets/Images/closeIcon.svg";
import pdficon from "../../../Assets/Svg/pdf-icon.svg";
import pdficonRed from "../../../Assets/Svg/pdf-icon-red.svg";
import { useDispatch, useSelector } from "react-redux";
import { acceptVendorPenalty, getVendorFollowCountAction, getVendorPenaltyById, rejectVendorPenalty } from "../../../Store/Actions/vendorFollowAction";
import PageLoader from "../../../Models/Pageloader/PageLoader";
import { formatNumberWithCommasandDecimals, vendorFollowCounts } from "../../../Utils/Utils";
import { toast } from "react-toastify";

const VendorFollow = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  // const navigate = useNavigate();
  // const [type, setType] = useState("all");
  // const [enableFilter, setEnableFilter] = useState(false);
  // const [show, setShow] = useState(false);
  // const [types, setTypes] = useState({
  //   penaltyClaimLetter: false,
  //   reasonrejectPenalty: false,
  //   rejectConfirmation: false,
  //   rejectSuccess: false,
  //   uploadFilePenaltyAccept: false,
  //   acceptConfirmation: false,
  //   acceptSuccess: false,
  // });

  // const popupState = {
  //   penaltyClaimLetter: false,
  //   reasonrejectPenalty: false,
  //   rejectConfirmation: false,
  //   rejectSuccess: false,
  //   uploadFilePenaltyAccept: false,
  //   acceptConfirmation: false,
  //   acceptSuccess: false,
  // };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState("");
  const [id,setId]=useState(null);
  const[penaltyShowData,setPenaltyShowData]=useState({});
  const [openClaimPopup,setOpenClaimPopup]=useState(false);
  const [openRejectPopup,setOpenRejectPopup]=useState(false);
  const [openAcceptPopup,setOpenAcceptPopup]=useState(false);
  const [openSuccessPopup,setOpenSuccessPopup]=useState(false);
  const [description, setDescription] = useState("");

  const {loading, vendorFollowCount} = useSelector((state) => state.VendorFollowReducer)
  let counts = vendorFollowCounts(vendorFollowCount)
  const {penaltyData,penaltyLoading}=useSelector((state) => state.VendorFollowReducer)
  const {penaltyData:data,acceptPenaltyLoading,acceptPenaltyError}=useSelector((state) => state.VendorFollowReducer)
  const { rejectPenaltyLoading, rejectPenaltyError } = useSelector((state) => state.VendorFollowReducer);
  const handleFileSelect = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".pdf";
    fileInput.multiple = true;
    fileInput.addEventListener("change", (e) => {
      const selected = e.target.files;
      const allowedFormats = ["application/pdf"];
      const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

      if (selected?.length > 4) {
        setError("Only 4 files are allowed.");
        return;
      }
      

      const newSelectedFiles = [];

      for (let i = 0; i < selected?.length; i++) {
        const file = selected[i];

        if (!allowedFormats.includes(file.type)) {
          setError("Only PDF files are allowed.");
          return;
        }

        if (file.size > maxSize) {
          setError("Only 20MB files are supported.");
          return;
        }

        newSelectedFiles.push(file);

      }

      // Clear any previous errors
      setError("");
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    });
    fileInput.click();
  };

  function acceptPenalty(id){
    dispatch(acceptVendorPenalty(id));
    
  }
  
  const handleChange = (event) => {
    const value = event.target.value;
    setDescription(value);
  };
  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };
  const handleRejectSubmit = async () => {
    
    if(!id){
      toast.error("Invalid id")
      setOpenRejectPopup(false);
      setOpenClaimPopup(false);
      return;
    }
    if(!description){
      toast.error("Please enter description in penalty reject")
      setOpenRejectPopup(false);
      setOpenClaimPopup(false);
      return;
    }
    if(selectedFiles?.length<1){
      toast.error("No Files selected")
      setOpenRejectPopup(false);
      setOpenClaimPopup(false);
      return;
    }
    dispatch(rejectVendorPenalty(id, description, selectedFiles));
    if(rejectPenaltyError){
      toast.error(rejectPenaltyError)
    }
    setOpenRejectPopup(false);
    setOpenSuccessPopup(true);
  };
  // const handleSubmit = () => {
  //   // Handle the submission of selected files here
  // };

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const handleTypeSelect = (selectedKey) => setType(selectedKey);

  // const toggleFilter = () => {
  //   setEnableFilter(!enableFilter);
  // };
  // const onClickAccept = () => {
  //   navigate("/vendor-follow");
  // };

  useEffect(()=>{
    dispatch(getVendorFollowCountAction())
  },[])
  useEffect(()=>{
    if(id){
      dispatch(getVendorPenaltyById(id));
    }
   
  },[id])
  // useEffect(() => {
  //   if (rejectPenaltyError) {
  //     toast.error(rejectPenaltyError);
  //   }
  // }, []);
  useEffect(()=>{
setPenaltyShowData(penaltyData)
  },[penaltyData])
  
  useEffect(()=>{
    setPenaltyShowData(penaltyData)
      },[penaltyData])
      

  const handleAcceptPenalty = async() => {
    if(!id){
      toast.error("Invalid id")
      setOpenAcceptPopup(false);
      setOpenClaimPopup(false);
      return;
    }
    if(selectedFiles?.length<1){
      toast.error("No Files selected")
      setOpenAcceptPopup(false);
      setOpenClaimPopup(false);
      return;
    }
   await dispatch(acceptVendorPenalty(id, selectedFiles));
   if(acceptPenaltyError){
    toast.error(acceptPenaltyError)
      setOpenAcceptPopup(false);
      setOpenClaimPopup(false);
   }
   else{
    setPenaltyShowData(data)
    setOpenAcceptPopup(false);
   }
  }
  const handlePrint = () => {
    const printContent = document.querySelector(".confirm").innerHTML;
    const newWindow = window.open("", "_blank");
    newWindow.document.open();
    newWindow.document.write(`
      <html>
        <head>
          <title>Penalty Claim Letter</title>
          <style>
            /* Style for print */
            body { font-family: Arial, sans-serif; color: inherit; }
            /* Hide buttons when printing */
            .penalty-accept-reject, .penalty-close-button { display: none !important; }
            /* Preserve colors and layout */
            .confirm { color: inherit; background-color: inherit; }
            .pr-details-view-file {
            display:none !important;}
            .penalty-input-box{
              border:none;
            }
            .penalty-input{
              box-sizing: border-box;
              border:none;
            }
              textarea.penalty-input {
            border: none !important;
            outline: none !important;
            resize: none !important;
            overflow: hidden !important;

             max-width: 100% !important;
    width: 100% !important;
            box-sizing: border-box;
            padding: 0;
            margin: 0;
            background: transparent;
          }
            .CURRENCY{
           
            }
            .signature-box{
              display: flex;
              flex-direction: row;
              margin: 52px 8px;
              justify-content: flex-end;
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${printContent}
        </body>
      </html>
    `);
    newWindow.document.close();
  };


  return (
    <>
    {(loading)&& <PageLoader/>}
  
      <DashBoardLayout>
        <div className="main-dashboard-body mb-3">
          <div className="container-fluid">
            <h1 className="vendor-follow-header">{t("Vendor Follow")}</h1>
            <br />
            <div className="containerr">
              <VendorFollowTable
                counts = {counts}
                setId={setId}
                setOpenClaimPopup={setOpenClaimPopup}
                // DelayClicked={() => {
                //   setTypes({
                //     ...popupState,
                //     penaltyClaimLetter: true,
                //   });
                // }}
              />

              <Popup
                open={openClaimPopup}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={true}
              >
                <div className="confirm">
                  <div className="pr-details-jobs-title-div">
                    <div className="d-flex">
                      {" "}
                      <h3 className="penalty-claim-header-text">
                        {t("Penalty Claim Letter")}
                      </h3>
                      <div className="pr-details-view-file mx-2 px-1" onClick={handlePrint}>
                        <img src={penaltyButton} alt="" />
                        <h5 className="pr-details-view-file-name px-2 pt-2">
                          {t("Download Penalty")}
                        </h5>
                      </div>
                    </div>
                    <button
                      className="penalty-close-button"
                      onClick={() => {
                        setOpenClaimPopup(false)
                      }}
                      //                             onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                  </div>

                  <div>
                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">
                        {t("To (Company Name)")}
                      </h3>
                      <input
                        className="penalty-input-box"
                        type="text"
                        size={90}
                        value={`${penaltyShowData?.data?.vendorData?.company_name_eng || ""} ${penaltyShowData?.data?.vendorData?.company_name_th || ""}`}
                     readOnly ></input>
                    </div>
                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">{t("Title")}</h3>
                      <input
                        className="penalty-input-box"
                        type="text"
                        size={90}
                        value={penaltyShowData?.data?.title || " "}
                        placeholder="Refer [POXXXXXX], Delivery date is [delivery date]. However you deliver to us on [GR date] "
                        readOnly ></input>
                    </div>

                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">
                        {t("Description")}
                      </h3>
                      <textarea
                        className="penalty-input"
                        rows="3"
                        placeholder="Under the delay penalty clause mentionable in General Terms and Condition, You agree to be penalized at the rate of 0.5% of the selling price per day and not exceeding than 10%. Due to you fall to delay delivery total [GR date] -[delivery date]."
                        cols="50"
                        value={penaltyShowData?.data?.description || " "}
                        name="comment"
                        form="usrform"
                        readOnly ></textarea>
                    </div>

                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">
                        {t("Penalty Amount")}
                      </h3>
                      
 <div
                className="penalty-title"
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  className="penalty-input"
                  value={formatNumberWithCommasandDecimals (penaltyShowData?.data?.penaltyClause)
                    || " "}
                  readOnly
                />
                <div
                className="CURRENCY"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "1px",
                    fontSize: "18px",
                  }}
                >
                  {penaltyShowData?.data?.penaltyCurrency || ""}
                </div>
              </div>
                    </div>
                    <div className="signature-box">
                     
                      <div className="signature-field">
                      <h2>Vendor Signature</h2>
                      <h3>{penaltyShowData?.data?.vendorData?.company_name_eng}</h3>
                      </div>
                       
                      </div> 

                    <div className="penalty-accept-reject">
                      <button
                        onClick={() => {
                         setOpenRejectPopup(true)
                        }}
                        className="reject"
                      >
                        {t("Reject")}
                      </button>

                      <button
                        onClick={() => {
                          setOpenAcceptPopup(true)
                        }}
                        className="accept"
                      >
                        {t("Accept")}
                      </button>
                    </div>
                  </div>
                </div>
              </Popup>
              {/*VIN-1058: Test case 42 : it would be fixed only after mangesh add one more field in backend also need to edit the action for this */}
              <Popup
                open={openAcceptPopup}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: '#999999A1',
                  zIndex: '99999',
                  backdropFilter: 'blur(5px)',
                }}
                closeOnDocumentClick={false}
              >
                <div className="reason-reject-penalty">
                  <div className="penalty-claim-header">
                    <h2 className="penalty-claim-header-text">
                      {t('Upload File Penalty Accept')}
                    </h2>
                    <button
                      className="penalty-close-button"
                      onClick={() => setOpenAcceptPopup(false)}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                  </div>
                  <div className="attach-file-reject">
                    <h4 className="reject-attach-text">
                      {selectedFiles?.length} {t('Attachment')}
                    </h4>
                    <button
                      className="reject-submit"
                      onClick={handleFileSelect}
                    >
                      {t('Attach File')}
                    </button>
                  </div>
                  {error && <p className="error-message">{error}</p>}
                  <div className="vendor-follow-attachment-list">
                    {selectedFiles?.map((file, index) => (
                      <div
                        key={index}
                        className="vendor-follow-attachment-item"
                      >
                        <img
                          src={pdficonRed}
                          alt="PDF Icon"
                          className="vendor-follow-pdf-icon"
                        />
                        <div className="ven-foll-fn-fs">
                          <span className="vendor-follow-attac-it-file-name">
                            {file.name}
                          </span>
                          <span className="vendor-follow-attac-it-file-size">
                            {(file.size / (1024 * 1024)).toFixed(2)} MB
                          </span>
                        </div>
                        <img
                          src={closeicon}
                          alt="Close Icon"
                          className="vendor-follow-cancel-icon"
                          onClick={() => handleRemoveFile(index)}
                        />
                      </div>
                    ))}
                  </div>
                  <p className="reject-ptext">
                    {t('Maximum file size 20 MB. Supported file formats: .pdf')}
                  </p>
                  <hr className="line-in-reject" />
                  <div className="reason-submit">
                    <button
                      className="reject-submit"
                      onClick={handleAcceptPenalty}
                    >
                      {t('Submit')}
                    </button>
                  </div>
                </div>
              </Popup>
              
              {/* <Popup
                open={openAcceptPopup}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="areyousure-penalty">
                  <div className="areyousure">
                    <button
                      onClick={() => {
                        setOpenAcceptPopup(false)
                      }}
                      className="areyousure-close-button"
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                    <img src={areYouSure} alt=""></img>
                    <h2 className="penalty-accept-header-text">
                      {t("Are you sure!")}
                    </h2>

                    <p className="reason-reject-ptext">
                      {t("You want to Accept this Penalty ?")}
                    </p>
                    <div className="cancel-confirm-penalty">
                      <button
                        className="reject-cancel"
                        onClick={() => {
                          setOpenAcceptPopup(false);
                        }}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        className="reject-submit"
                        onClick={() => {
                         acceptPenalty(id);
                         if(acceptPenaltyError){
                          toast.error(acceptPenaltyError)
                            setOpenAcceptPopup(false);
                         }
                         else{
                          setPenaltyShowData(data)
                          setOpenAcceptPopup(false);
                          toast.success('Penalty Accepted', {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: "Bounce",
                            });
                         }
                        }}
                      >
                        {t("Confirm")}
                      </button>
                    </div>
                  </div>
                </div>
              </Popup> */}
              {/* <Popup
                open={types.acceptSuccess}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="areyousure-penalty">
                  <div className="areyousure">
                    <button
                      className="areyousure-close-button"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                    <img src={successLogo} alt=""></img>
                    <h2 className="success-done">Done!</h2>
                    <p className="success-ptext">
                      Accept Pennalty successfully
                    </p>

                    <button
                      className="reject-submit"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                    >
                      {" "}
                      {t("Ok")}
                    </button>
                  </div>
                </div>
              </Popup> */}
              <Popup
                open={openRejectPopup}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: '#999999A1',
                  zIndex: '99999',
                  backdropFilter: 'blur(5px)',
                }}
                closeOnDocumentClick={false}
              >
                <div className="reason-reject-penalty">
                  <div className="penalty-claim-header">
                    <h2 className="penalty-claim-header-text">
                      {t('Reason Of Rejection')}
                    </h2>
                    <button
                      className="penalty-close-button"
                      onClick={() => setOpenRejectPopup(false)}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                  </div>

                  <div>
                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">
                        {t('Description')}
                      </h3>
                      <input
                        className="penalty-input"
                        type="text"
                        size={90}
                        value={description}
  onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="attach-file-reject">
                    <h4 className="reject-attach-text">
                      {selectedFiles?.length} {t('Attachment')}
                    </h4>
                    <button
                      className="reject-submit"
                      onClick={handleFileSelect}
                    >
                      {t('Attach File')}
                    </button>
                  </div>
                  {error && <p className="error-message">{error}</p>}
                  <div className="vendor-follow-attachment-list">
                    {selectedFiles?.map((file, index) => (
                      <div
                        key={index}
                        className="vendor-follow-attachment-item"
                      >
                        <img
                          src={pdficon}
                          alt="PDF Icon"
                          className="vendor-follow-pdf-icon"
                        />
                        <div className="ven-foll-fn-fs">
                          <span className="vendor-follow-attac-it-file-name">
                            {file.name}
                          </span>
                          <span className="vendor-follow-attac-it-file-size">
                            {(file.size / (1024 * 1024)).toFixed(2)} MB
                          </span>
                        </div>
                        <img
                          src={closeicon}
                          alt="Close Icon"
                          className="vendor-follow-cancel-icon"
                          onClick={() => handleRemoveFile(index)}
                        />
                      </div>
                    ))}
                  </div>
                  <p className="reject-ptext">
                    {t('Maximum file size 20 MB. Supported file formats: .pdf')}
                  </p>
                  <hr className="line-in-reject" />
                  <div className="reason-submit">
                    <button
                      className="reject-submit"
                      onClick={handleRejectSubmit}
                    >
                      {t('Submit')}
                    </button>
                  </div>
                </div>
              </Popup>
              <Popup
                open={openRejectPopup}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="reason-reject-penalty">
                  <div className="penalty-claim-header">
                    <h2 className="penalty-claim-header-text">
                      {t("Reason Of Rejection")}
                    </h2>
                    <button
                      className="penalty-close-button"
                      onClick={() => {
                       setOpenRejectPopup(false);
                      }}
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                  </div>

                  <div>
                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">
                        {t("Description")}
                      </h3>
                      <input value={description} onChange={handleChange} className="penalty-input" type="text" size={90} />
                    </div>
                  </div>
                  <div className="attach-file-reject">
                    <h4 className="reject-attach-text">
                      {selectedFiles?.length} {t("Attachment")}
                    </h4>
                    <button
                      className="reject-submit"
                      onClick={handleFileSelect}
                    >
                      {t("Attach File")}
                    </button>
                  </div>
                  {error && <p className="error-message">{error}</p>}
                  <div className="vendor-follow-attachment-list">
                    {selectedFiles?.map((file, index) => (
                      <div
                        key={index}
                        className="vendor-follow-attachment-item"
                      >
                        <img
                          src={pdficon}
                          alt="PDF Icon"
                          className="vendor-follow-pdf-icon"
                        />
                        <div className="ven-foll-fn-fs">
                          <span className="vendor-follow-attac-it-file-name">
                            {file.name}
                          </span>
                          <span className="vendor-follow-attac-it-file-size">
                            {(file.size / (1024 * 1024)).toFixed(2)} MB
                          </span>
                        </div>
                        <img
                          src={closeicon}
                          alt="Close Icon"
                          className="vendor-follow-cancel-icon"
                          onClick={() => handleRemoveFile(index)}
                        />
                      </div> 
                    ))}
                  </div>
                  <p className="reject-ptext">
                    {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                  </p>
                 <hr className="line-in-reject" />
                  <div className="reason-submit">
                    <button
                      className="reject-submit"
                      onClick={handleRejectSubmit}
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </Popup>
            
              <Popup
                open={openSuccessPopup}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="areyousure-penalty">
                  <div className="areyousure">
                    <button
                      className="areyousure-close-button"
                      onClick={() => {
                        setOpenSuccessPopup(false)
                      }}
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                    <img src={successLogo} alt=""></img>
                    <h2 className="success-done">Done!</h2>
                    <p className="success-ptext">
                      {t("Reason reject Penalty successfully")}
                    </p>

                    <button
                      className="reject-submit"
                      onClick={() => {
                        setOpenSuccessPopup(false)
                       
                      }}
                    >
                      {" "}
                      {t("Ok")}
                    </button>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </div>
      </DashBoardLayout>
    </>
  );
};

export default VendorFollow;
